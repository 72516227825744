import { Card, CardContent, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { PieChart } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import dayjs, { Dayjs } from "dayjs";
import * as React from "react";
import { useMemo } from "react";
import { Income } from "../../hooks/incomes/useLoadIncomes";

interface Props {
  data: Income[];
}

const StyledText = styled("text")(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: 20,
}));

function PieCenterLabel({ children }: { children: React.ReactNode }) {
  const theme = useTheme();
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText
      x={left + width / 2}
      y={top + height / 2}
      sx={{ fill: theme.custom.text, fontSize: 30 }}
    >
      {children}
    </StyledText>
  );
}

function IncomesSummaryCard({ data }: Props) {
  const theme = useTheme();

  const chartData = useMemo(() => {
    const res: Record<string, number> = { paid: 0, unpaid: 0 };
    const today = dayjs(new Date());
    data.forEach((d) => {
      if (dayjs(d.date).isBefore(today) || dayjs(d.date).isSame(today)) {
        res.paid += d.amount;
      } else {
        res.unpaid += d.amount;
      }
    });
    return [
      { label: "Unpaid", value: res.unpaid },
      { label: "Paid", value: res.paid },
    ];
  }, [data]);

  const nextPay = useMemo(() => {
    const today = dayjs(new Date());
    const payDays: { date: Dayjs; amount: number; accountName: string }[] =
      data.map((d) => ({
        date: dayjs(d.date),
        amount: d.amount,
        accountName: d.accountName,
      }));
    const futurePayDays = payDays.filter((d) => d.date.isAfter(today));
    if (!futurePayDays.length) return 0;
    const nextPayDate = futurePayDays.sort((a, b) => a.date.diff(b.date))[0];
    return {
      days: nextPayDate.date.diff(today, "days"),
      amount: nextPayDate.amount,
      accountName: nextPayDate.accountName,
    };
  }, [data]);

  const label = `£ ${chartData
    .reduce((acc, { value }) => acc + value, 0)
    .toFixed(2)}`;

  const size = {
    width: 255,
    height: 210,
  };

  return (
    <Card
      variant="outlined"
      sx={{
        backgroundColor: theme.custom.main,
        borderColor: theme.custom.border,
        height: "100%",
      }}
    >
      <>
        <CardContent>
          <Typography
            gutterBottom
            sx={{
              color: theme.custom.text,
              fontSize: 14,
            }}
          >
            Income
          </Typography>
          <PieChart
            margin={{ right: 5 }}
            series={[
              {
                data: chartData,
                innerRadius: 70,
                outerRadius: 100,
                sortingValues: "none",
              },
            ]}
            sx={{ ".MuiPieArc-root": { stroke: theme.custom.text } }}
            colors={["#4d194d", "#3e1f47"]}
            slotProps={{
              legend: { hidden: true },
            }}
            {...size}
          >
            <PieCenterLabel>{label}</PieCenterLabel>
          </PieChart>
          {/* if 0 days then pay was today or no future payments */}
          {!!nextPay && (
            <>
              <Typography
                sx={{
                  color: theme.custom.text,
                  fontSize: 14,
                  mt: 2,
                }}
              >
                {nextPay.days} days until next paid
              </Typography>
              <Typography
                sx={{
                  color: theme.custom.text,
                  fontSize: 12,
                }}
              >
                (£{nextPay.amount} to {nextPay.accountName})
              </Typography>
            </>
          )}
        </CardContent>
      </>
    </Card>
  );
}

export default IncomesSummaryCard;
